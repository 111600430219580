import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListDataSingle } from 'interface/Data';
import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePassw } from 'fixedData';
import { SetUserData } from 'interface/Users';
import { SetListPag } from 'interface/Page';

// Alt Status data
export function Reg_AltStatus(userId, type, id, origin, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append("id", id);
    data.append('userId', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch (origin) {
            case "architects":
                    SetListDataSingle('architects', response.data['architects']);
                    SetListDataSingle('project', response.data['project']);
                break;
                
            case "approval_registrations":
                    SetListDataSingle('access_dash', response.data['access_dash']);
                break;
                
            case "product":
                    SetListDataSingle('product', response.data['product']);
                break;

            case "downloads":                    
                    SetListDataSingle('downloads', response.data['downloads']);
                    SetListDataSingle('downloads_category', response.data['downloads_category']);
                break;
        
            default:
                    SetListDataSingle('access_dash', response.data['access_dash']);
                    SetListDataSingle(origin, response.data[origin]);
                break;
        }
        CallbackSuccess();

    }).catch((error)=>{

        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url: process.env.REACT_APP_PHP + 'inc/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        switch (response.data) {
            case "Email enviado com sucesso":
                CallbackSuccess();
                break;
            case "Email não enviado":
                CallbackError_Send();
                break;
            case "E-mail não encontrado":
                CallbackError_NotEmail();
                break;
            case "Erro ao enviar mensagem":
                CallbackError();
                break;
            default:
                CallbackError();
                break;
        }

    }).catch((error)=>{

        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : process.env.REACT_APP_PHP + 'inc/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        if(response.data == "conectado"){
            CallbackSuccess();
        }else {
            CallbackError();
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Save crop
export function Reg_CropImg(origin, userId, idGiven, idPage, type, file, positionX, positionY, width, height, showWidth, widthReal, CallbackSuccess, CallbackError){
    const data = new FormData();
    
    data.append('type_page', origin);
    data.append('edit_data', 'add_file');

    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);
    data.append('type_file', type);
    data.append('file', file);
    data.append('positionX', positionX);
    data.append('positionY', positionY);
    data.append('width', width);
    data.append('height', height);
    data.append('showWidth', showWidth);
    data.append('widthReal', widthReal);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle(origin, response.data[origin]);
        
        if(type == 'file'){
            if(idGiven == userId){
                SetUserData('file', response.data.file);
            }
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Shopkeepers
export function Reg_Shopkeepers(userId, origin, idGiven, idPage, name, cnpj, socialReason, stateRegistration, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, coverFile, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, photoFile, photoPositionX, photoPositionY, photoWidth, photoHeight, photoShowWidth, photoWidthReal, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'shopkeepers');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);

    data.append('name', name);
    data.append('cnpj', cnpj);
    data.append('social_reason', socialReason);
    data.append('state_registration', stateRegistration);

    data.append('road', road);
    data.append('number', number);
    data.append('neighborhood', neighborhood);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);

    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('tiktok', tiktok);
    data.append('pinterest', pinterest);
    data.append('site', site);
    
    data.append('userName', userName);
    data.append('userEmail', userEmail);
    data.append('userPassword', userPassword);
 
    // cover
    data.append('coverFile', coverFile);
    data.append('coverPositionX', coverPositionX);
    data.append('coverPositionY', coverPositionY);
    data.append('coverWidth', coverWidth);
    data.append('coverHeight', coverHeight);
    data.append('coverShowWidth', coverShowWidth);
    data.append('coverWidthReal', coverWidthReal);
    
    // file
    data.append('photoFile', photoFile);
    data.append('photoPositionX', photoPositionX);
    data.append('photoPositionY', photoPositionY);
    data.append('photoWidth', photoWidth);
    data.append('photoHeight', photoHeight);
    data.append('photoShowWidth', photoShowWidth);
    data.append('photoWidthReal', photoWidthReal);

    listContact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_contact[]', elem.contact);
        data.append('contact_whatsapp[]', elem.whatsapp);
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/shopkeepers.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('shopkeepers', response.data['shopkeepers']);
        SetListDataSingle('access_dash', response.data['access_dash']);
        
        if(origin == ""){            
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 2);
                        
            cookies.set(typeCookie, 'dashboard', { path: '/', expires: add3Days }, cookiesRegister);
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
            if(response.data.pass !=0){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }

            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }else {
            SetListPag('currentPageId', response.data.idPage);
        }
        
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Architect
export function Reg_Architects(userId, origin, idGiven, idPage, nameFantasy, cnpj, socialReason, stateRegistration, name, cau, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, coverFile, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, photoFile, photoPositionX, photoPositionY, photoWidth, photoHeight, photoShowWidth, photoWidthReal, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'architects');
    data.append('edit_data', 'edit_or_register');

    data.append('typePageRegister', origin);
    data.append('userId', userId);
    data.append('idGiven', idGiven);

    data.append('idPage', idPage);
    data.append('nameFantasy', nameFantasy);
    data.append('cnpj', cnpj);
    data.append('social_reason', socialReason);
    data.append('state_registration', stateRegistration);

    data.append('name', name);
    data.append('cau', cau);

    data.append('road', road);
    data.append('number', number);
    data.append('neighborhood', neighborhood);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);

    data.append('facebook', facebook);
    data.append('instagram', instagram);
    data.append('tiktok', tiktok);
    data.append('pinterest', pinterest);
    data.append('site', site);
    
    data.append('userName', userName);
    data.append('userEmail', userEmail);
    data.append('userPassword', userPassword);
 
    // cover
    data.append('coverFile', coverFile);
    data.append('coverPositionX', coverPositionX);
    data.append('coverPositionY', coverPositionY);
    data.append('coverWidth', coverWidth);
    data.append('coverHeight', coverHeight);
    data.append('coverShowWidth', coverShowWidth);
    data.append('coverWidthReal', coverWidthReal);
    
    // file
    data.append('photoFile', photoFile);
    data.append('photoPositionX', photoPositionX);
    data.append('photoPositionY', photoPositionY);
    data.append('photoWidth', photoWidth);
    data.append('photoHeight', photoHeight);
    data.append('photoShowWidth', photoShowWidth);
    data.append('photoWidthReal', photoWidthReal);

    listContact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_contact[]', elem.contact);
        data.append('contact_whatsapp[]', elem.whatsapp);
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/architects.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        if(origin == ""){            
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 2);
                        
            cookies.set(typeCookie, 'dashboard', { path: '/', expires: add3Days }, cookiesRegister);
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
            if(response.data.pass !=0){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }

            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }else {
            SetListPag('currentPageId', response.data.idPage);
        }
        SetListDataSingle('access_dash', response.data['access_dash']);
        SetListDataSingle('architects', response.data['architects']);

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Architect - Details
export function Reg_ArchitectsDetails(userId, idPage, cover, title, text, listGallery, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'architects');
    data.append('edit_data', 'project');

    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('cover', cover);
    data.append('title', title);
    data.append('text', text);

    listGallery.forEach((elem, index) => {
        data.append('gallery_id[]', elem.id);
        data.append('gallery_type[]', elem.type);
        data.append('gallery_name[]', elem.name);
        data.append('gallery_file_' + index, elem.file);
        data.append('gallery_link[]', elem.link);
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/architects.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('project', response.data['project']);
        SetListPag('currentPageId', response.data.idPage);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Images
export function Reg_ImagesOrPdf(userId, origin, type, idPage, category, newCategory, title, file, CallbackSuccess, CallbackError){
    const data = new FormData();
    
    data.append('type_page', 'downloads');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('origin', origin);
    data.append('type', type);
    data.append('idPage', idPage);
    data.append('category', category);
    data.append('new_category', newCategory);
    data.append('title', title);
    data.append('file', file);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/downloads.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('downloads', response.data['downloads']);
        SetListDataSingle('downloads_category', response.data['downloads_category']);
        CallbackSuccess();

    }).catch((error)=>{
        console.log(error);
        CallbackError();
    })
}


// Assistants
export function Reg_Assistants(userId, idGiven, idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, cpf, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, page, userName, userEmail, userPassword, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'assistants');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idGiven', idGiven);
    data.append('idPage', idPage);

    data.append('cover', cover);
    data.append('coverPositionX', coverPositionX);
    data.append('coverPositionY', coverPositionY);
    data.append('coverWidth', coverWidth);
    data.append('coverHeight', coverHeight);
    data.append('coverShowWidth', coverShowWidth);
    data.append('coverWidthReal', coverWidthReal);
    
    data.append('file', file);
    data.append('filePositionX', filePositionX);
    data.append('filePositionY', filePositionY);
    data.append('fileWidth', fileWidth);
    data.append('fileHeight', fileHeight);
    data.append('fileShowWidth', fileShowWidth);
    data.append('fileWidthReal', fileWidthReal);

    data.append('name', name);
    data.append('cpf', cpf);

    data.append('road', road);
    data.append('number', number);
    data.append('neighborhood', neighborhood);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);

    data.append('facebook', facebook);
    data.append('instagram', instagram);
    
    data.append('userName', userName);
    data.append('userEmail', userEmail);
    data.append('userPassword', userPassword);

    listContact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_contact[]', elem.contact);
        data.append('contact_whatsapp[]', elem.whatsapp);
    });

    page.forEach((elem, index) => {
        data.append('page_index[]', index);
        data.append('page_id[]', elem.id);
        data.append('page_name[]', elem.name);
        data.append('page_page[]', elem.page);
        data.append('page_type[]', elem.type);
        data.append('page_order[]', elem.order);
        
        elem.list_sub.map((elem_1, index_1)=>{
            data.append('sub_page_index[]', index);
            data.append('sub_page_id[]', elem_1.id);
            data.append('sub_page_name[]', elem_1.name);
            data.append('sub_page_page[]', elem_1.page);
            data.append('sub_page_type[]', elem_1.type);
            data.append('sub_page_order[]', elem_1.order);
        })
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/assistants.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListDataSingle('access_dash', response.data['access_dash']);
        SetListDataSingle('assistants', response.data['assistants']);
        SetListPag('currentPageId', response.data.idPage);
        
        if(file == ''){
            if(userId == idPage){
                SetUserData('file', response.data.file);
            }
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Assistants
export function Reg_UserAdministrator(userId, idPage, cover, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, file, filePositionX, filePositionY, fileWidth, fileHeight, fileShowWidth, fileWidthReal, name, email, password, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');

    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('cover', cover);
    data.append('coverPositionX', coverPositionX);
    data.append('coverPositionY', coverPositionY);
    data.append('coverWidth', coverWidth);
    data.append('coverHeight', coverHeight);
    data.append('coverShowWidth', coverShowWidth);
    data.append('coverWidthReal', coverWidthReal);
    
    data.append('file', file);
    data.append('filePositionX', filePositionX);
    data.append('filePositionY', filePositionY);
    data.append('fileWidth', fileWidth);
    data.append('fileHeight', fileHeight);
    data.append('fileShowWidth', fileShowWidth);
    data.append('fileWidthReal', fileWidthReal);

    data.append('name', name);
    data.append('email', email);
    data.append('password', password);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('access_dash', response.data['access_dash']);
        
        if(userId == idPage){                         
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 2);
                        
            cookies.set(typeCookie, 'dashboard', { path: '/', expires: add3Days }, cookiesRegister);
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);
            if(response.data.pass !=0){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }

            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
            if(response.data.file !=''){
                SetUserData('file', response.data.file);
            }
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Product
export function Reg_Product(userId, idPage, codeSku, id3d, title, inmetro, highlightTitle, file, subtitle, typeVideo, linkVideo, whereBuy, descTitle, description, typeGallery, listImg, listCategory, listComponents, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'product');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('code_sku', codeSku);
    data.append('id_3d', id3d);
    data.append('title', title);
    data.append('inmetro', inmetro);
    data.append('highlight_title', highlightTitle);
    data.append('file', file);
    data.append('subtitle', subtitle);
    data.append('type_video', typeVideo);
    data.append('link_video', linkVideo);
    data.append('where_buy', whereBuy);
    data.append('desc_title', descTitle);
    data.append('description', description);
    data.append('type_gallery', typeGallery);

    // category
    listCategory.forEach((elem, index) => {
        data.append('category_id[]', elem.id);
        data.append('category_id_category[]', elem.id_category);
        data.append('category_newCategory[]', elem.newCategory);
    });

    // img
    listImg.forEach((elem, index) => {
        data.append('img_id[]', elem.id);
        data.append('img_name[]', elem.name);

        elem.list.forEach((elem_1, index_1) => {
            data.append('img_list_id_' + index + '[]', elem_1.id);
            data.append('img_list_name_' + index + '[]', elem_1.name);
            data.append('img_list_file_' + index + '_' + index_1, elem_1.file);
        });
    });

    // components
    console.log(listComponents);
    listComponents.forEach((elem, index) => {  
        data.append('components_id[]', elem.id);
        data.append('components_name[]', elem.name);
        data.append('components_type[]', elem.type);
        data.append('components_size[]', elem.size);
        data.append('components_separator[]', elem.separator_);
        data.append('components_nameNav[]', elem.nameNav);
        data.append('components_image_' + index, elem.image);
        
        elem.itens.forEach((elem_1, index_1) => {
            data.append('components_list_id_' + index + '[]', elem_1.id);
            data.append('components_list_name_' + index + '[]', elem_1.name);

            elem_1.list.forEach((elem_2, index_2) => {
                data.append('components_list_itens_id_' + index + '_' + index_1 + '[]', elem_2.id);
                data.append('components_list_itens_id_itens_' + index + '_' + index_1 + '[]', elem_2.id_itens);
                data.append('components_list_itens_newItens_' + index + '_' + index_1 + '[]', elem_2.newItens);
                data.append('components_list_itens_code_sku_' + index + '_' + index_1 + '[]', elem_2.code_sku)
            })
        })    
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/product.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('product', response.data['product']);
        SetListDataSingle('list_data', response.data['list_data']);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
export function Reg_ProductTemplate(userId, idPage, title, typeGallery, highlightTitle, listComponents, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();
    
    data.append('type_page', 'product_template');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('title', title);
    data.append('type_gallery', typeGallery);
    data.append('highlight_title', highlightTitle);

    // components
    listComponents.forEach((elem, index) => {  
        data.append('components_id[]', elem.id);
        data.append('components_name[]', elem.name);
        data.append('components_type[]', elem.type);
        data.append('components_size[]', elem.size);
        data.append('components_separator[]', elem.separator_);
        data.append('components_nameNav[]', elem.nameNav);
        
        elem.itens.forEach((elem_1, index_1) => {
            data.append('components_list_id_' + index + '[]', elem_1.id);
            data.append('components_list_name_' + index + '[]', elem_1.name);

            elem_1.list.forEach((elem_2, index_2) => {
                data.append('components_list_itens_id_' + index + '_' + index_1 + '[]', elem_2.id);
                data.append('components_list_itens_id_itens_' + index + '_' + index_1 + '[]', elem_2.id_itens);
                data.append('components_list_itens_newItens_' + index + '_' + index_1 + '[]', elem_2.newItens);
            })
        })    
    });

    Axios({
        url : process.env.REACT_APP_PHP + 'page/product_template.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('product_template', response.data['product_template']);
        SetListDataSingle('list_data', response.data['list_data']);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Blog
export function Reg_Blog(userId, idPage, title, subtitle, text, banner, CallbackSuccess, CallbackError){
    const data = new FormData();
    
    data.append('type_page', 'blog');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('text', text);
    data.append('banner', banner);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/blog.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data, banner);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('blog', response.data['blog']);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}


// Technical assistance
export function Reg_TechnicalAssistance(userId, idPage, name, state, city, uf, address, complement, email, responsable, phone, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'technical_assistance');
    data.append('edit_data', 'edit_or_register');
    data.append('userId', userId);
    data.append('idPage', idPage);

    data.append('name', name);
    data.append('state', state);
    data.append('city', city);
    data.append('uf', uf);
    data.append('address', address);
    data.append('complement', complement);
    data.append('email', email);
    data.append('responsable', responsable);
    data.append('phone', phone);

    Axios({
        url : process.env.REACT_APP_PHP + 'page/technical_assistance.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);
        SetListPag('currentPageId', response.data.idPage);
        SetListDataSingle('technical_assistance', response.data['technical_assistance']);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}