import { useState, useEffect } from 'react';

import './Contents.css';

import Cookies from "universal-cookie";

import { SetListPag } from 'interface/Page';
import { GetUserData, RegisterUserData, UnRegisterUserPage } from 'interface/Users';

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePageId, typeCookiePassw } from 'fixedData';

import Contents_Architect from './Architect';
import Contents_Assistants from './Assistants';
import Contents_Shopkeepers from './Shopkeepers';
import Contents_Administrator from './Administrator';

import PopUP_ShowFile from 'components/PopUp/ShowFile';
import PopUP_Historic from 'components/PopUp/Historic';
import PopUp_PhotoCrop from 'components/PopUp/PhotoCrop';
import PopUP_DetailsUser from 'components/PopUp/DetailsUser';
import PopUP_Confirmation from 'components/PopUp/Confirmation';
import PopUP_ReturnResponse from 'components/PopUp/ReturnResponse';
import PopUP_EditOrNewDownloads from 'components/PopUp/EditOrNewDownloads';

export default function Contents(props){

    const cookies = new Cookies();
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('nivel'));

    const [ status, setStatus ] = useState(false);  

    function CkickPage(value){
        SetListPag('currentPage', value);
        document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });

        if(document.body.clientWidth <= 1050){
            setStatus(false);
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(false);
            }
        });
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
        cookies.remove(typeCookiePageId, '', '/', cookiesRegister);
    }

    function ShowTypeAccess(){
        switch (userAccess) {
            case 0:
                return <Contents_Shopkeepers CkickPage={ CkickPage } Logout={ Logout } userFile={ userFile } userName={ userName } setLoading={ props.setLoading } />;
            case 1:
                return <Contents_Architect CkickPage={ CkickPage } Logout={ Logout } userFile={ userFile } userName={ userName } setLoading={ props.setLoading } />;
            case 2:
                return <Contents_Assistants CkickPage={ CkickPage } Logout={ Logout } userFile={ userFile } userName={ userName } setLoading={ props.setLoading } />;
            case 3:
                return <Contents_Administrator CkickPage={ CkickPage } Logout={ Logout } userFile={ userFile } userName={ userName } setLoading={ props.setLoading } />;
        }
    }

    useEffect(()=>{
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('nivel', setUserAccess);

        if(document.body.clientWidth <= 1050){
            setStatus(false);
        }
        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1050){
                setStatus(false);
            }
        });

        return ()=>{
            UnRegisterUserPage('file', setUserFile);
            UnRegisterUserPage('name', setUserName);
            UnRegisterUserPage('nivel', setUserAccess);
        };
    }, []);
    
    return (
        <>
            { ShowTypeAccess() }
            
            <PopUP_DetailsUser setLoading={ props.setLoading } />
            <PopUP_Historic setLoading={ props.setLoading } />
            <PopUP_EditOrNewDownloads setLoading={ props.setLoading } />
            <PopUP_ReturnResponse setLoading={ props.setLoading } />
            <PopUP_Confirmation setLoading={ props.setLoading } />
            <PopUP_ShowFile />
            <PopUp_PhotoCrop setLoading={ props.setLoading } />
        </>
    );
}