import { useState, useEffect } from "react";

import './Profile.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import BtnSave from "components/BtnSave";
import { Svg_Add, Svg_Delete, Svg_Edit, Svg_Link, Svg_Logo_Abbreviated } from "components/Svg";

import { colorIcon } from "fixedData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { cepMask, cnpjMask } from "services/Mask";
import { Reg_CropImg, Reg_Shopkeepers } from "services/RegisterData";

export default function Page_Shopkeepers_Profile(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ listUser, setListUser ] = useState(GetDataPage('access_dash'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('shopkeepers'));

    const [ cover, setCover ] = useState(InitialData('cover'));
    const [ file, setFile ] = useState(InitialData('file'));   

    const [ idGiven, setIdGiven ] = useState(InitialData('id_given'));
    const [ name, setName ] = useState(InitialData('name'));
    const [ cnpj, setCnpj ] = useState(InitialData('cnpj'));
    const [ socialReason, setSocialReason ] = useState(InitialData('social_reason'));
    const [ stateRegistration, setStateRegistration ] = useState(InitialData('state_registration'));

    const [ road, setRoad ] = useState(InitialData('road'));
    const [ number, setNumber ] = useState(InitialData('number'));
    const [ neighborhood, setNeighborhood ] = useState(InitialData('neighborhood'));
    const [ cep, setCep ] = useState(InitialData('cep'));
    const [ city, setCity ] = useState(InitialData('city'));
    const [ uf, setUf ] = useState(InitialData('uf'));

    const [ facebook, setFacebook ] = useState(InitialData('facebook'));
    const [ instagram, setInstagram ] = useState(InitialData('instagram'));
    const [ youtube, setYoutube ] = useState(InitialData('youtube'));
    const [ tiktok, setTiktok ] = useState(InitialData('tiktok'));
    const [ pinterest, setPinterest ] = useState(InitialData('pinterest'));
    const [ site, setSite ] = useState(InitialData('site'));
    
    const [ listContact, setListContact ] = useState(InitialData('list_contact'));
    const [ listImg, setListImg ] = useState(InitialData('list_img'));

    const [ userName, setUserName ] = useState(DataLogin('name'));
    const [ userEmail, setUserEmail ] = useState(DataLogin('email'));
    const [ userPassword, setUserPassword ] = useState('');

    // cover
    const [ coverFile, setCoverFile ] = useState('');
    const [ coverPositionX, setCoverPositionX ] = useState(0);
    const [ coverPositionY, setCoverPositionY ] = useState(0);
    const [ coverWidth, setCoverWidth ] = useState(0);
    const [ coverHeight, setCoverHeight ] = useState(0);
    const [ coverShowWidth, setCoverShowWidth ] = useState(0);
    const [ coverWidthReal, setCoverWidthReal ] = useState(0);

    // photo
    const [ photoFile, setPhotoFile ] = useState('');
    const [ photoPositionX, setPhotoPositionX ] = useState(0);
    const [ photoPositionY, setPhotoPositionY ] = useState(0);
    const [ photoWidth, setPhotoWidth ] = useState(0);
    const [ photoHeight, setPhotoHeight ] = useState(0);
    const [ photoShowWidth, setPhotoShowWidth ] = useState(0);
    const [ photoWidthReal, setPhotoWidthReal ] = useState(0);

    function InitialData(type){
        if(GetUserData('nivel') == 0){
            const newData = GetDataPage('shopkeepers').find(item => item.id == props.idPage);
            if(newData){
                return newData[type];
            }
            if(type == 'id_given'){
                return 0;
            }
            if(type == 'list_img' || type == 'list_contact'){
                return [];
            }
            return '';
        }else {
            const newData = GetDataPage('shopkeepers').find(item => item.id == idPage);
            if(newData){
                return newData[type];
            }
            if(type == 'id_given'){
                return 0;
            }
            if(type == 'list_img' || type == 'list_contact'){
                return [];
            }
            return '';
        } 
    }

    function DataLogin(type){
        const newData = GetDataPage('access_dash').find(item => item.id == InitialData('id_given'));
        if(newData){
            return newData[type];
        }
        return '';
    }

    function RemuveFile(id){
        SetModalData('Confirmation', { "id": id, "status": 0, "origin": "shopkeepers", "type": "delete_photo", "name": "Remover foto" });
        SetModalState('Confirmation', true);
    }   

    function RegisterCropData(img){
        if(idGiven == 0){
            if(img.type === 'cover'){
                setCover(img.file);
                setCoverFile(img.file);
                setCoverPositionX(img.positionX);
                setCoverPositionY(img.positionY);
                setCoverWidth(img.width);
                setCoverHeight(img.height);
                setCoverShowWidth(img.showWidth);
                setCoverWidthReal(img.widthReal);

            }else if(img.type === 'file'){
                setFile(img.file);  
                setPhotoFile(img.file);
                setPhotoPositionX(img.positionX);
                setPhotoPositionY(img.positionY);
                setPhotoWidth(img.width);
                setPhotoHeight(img.height);
                setPhotoShowWidth(img.showWidth);
                setPhotoWidthReal(img.widthReal);
            }
        }else {
            props.setLoading(true);
            Reg_CropImg('shopkeepers', userId, idGiven, GetUserData('nivel') == 0 ? props.idPage : idPage, img.type, img.file, img.positionX, img.positionY, img.width, img.height, img.showWidth, img.widthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function CropImg(type, value, index){
        let proportionX = 0;
        let proportionY = 0;

        // file and list file
        if(type == 'file' || type == 'list_Img'){
            proportionX = 16;
            proportionY = 16;
        // cover 
        }else if(type == 'cover'){
            proportionX = 16;
            proportionY = 3;
        }     
        SetModalData('PhotoCrop', { "type": type, "index": index, "showImg": URL.createObjectURL(value), "file": value, "RegisterCropData": RegisterCropData, "proportionX": proportionX, "proportionY": proportionY });   
        SetModalState('PhotoCrop', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "typeFile": "alone", "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    /* contact */
    function HandleData(type, index, value){
        const newData = [...listContact];
        newData[index][type] = value;
        setListContact(newData);
    }
    function Delete(index, id, value){
        if(id == 0){
            const newData = [...listContact];
            newData.splice(index, 1);
            setListContact(newData);
        }else {
            SetModalData('Confirmation', { "type": "delete_contact", "status": 0, "id": id, "origin": "shopkeepers", "name": value });
            SetModalState('Confirmation', true);
        }
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        if(GetUserData('nivel') != 0){            
            if(idPage == 0){
                if(listUser.find(item => item.email === userEmail)){
                    SetModalData('ReturnResponse', { "page": "erro", "text": 'Já existe um cadastro utilizando o e-mail "' + userEmail + '", é necessário utilizar outro e-mail para finalizar o cadastro!' });
                    SetModalState('ReturnResponse', true);
                }else {
                    props.setLoading(true);
                    Reg_Shopkeepers(userId, props.origin, idGiven, idPage, name, cnpj, socialReason, stateRegistration, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, coverFile, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, photoFile, photoPositionX, photoPositionY, photoWidth, photoHeight, photoShowWidth, photoWidthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
                }
            }else {
                props.setLoading(true);
                Reg_Shopkeepers(userId, props.origin, idGiven, idPage, name, cnpj, socialReason, stateRegistration, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, coverFile, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, photoFile, photoPositionX, photoPositionY, photoWidth, photoHeight, photoShowWidth, photoWidthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
            }

        }else{            
            props.setLoading(true);
            Reg_Shopkeepers(userId, props.origin, idGiven, props.idPage, name, cnpj, socialReason, stateRegistration, road, number, neighborhood, cep, city, uf, listContact, facebook, instagram, tiktok, pinterest, site, userName, userEmail, userPassword, coverFile, coverPositionX, coverPositionY, coverWidth, coverHeight, coverShowWidth, coverWidthReal, photoFile, photoPositionX, photoPositionY, photoWidth, photoHeight, photoShowWidth, photoWidthReal, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('shopkeepers', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        RegisterDataPage('access_dash', setListUser);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('shopkeepers', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
            UnRegisterDataPage('access_dash', setListUser);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setListUser(GetDataPage('access_dash'));
        setDataPage(GetDataPage('shopkeepers'));

        setCover(InitialData('cover'));
        setFile(InitialData('file'));
        
        setIdGiven(InitialData('id_given'));
        setName(InitialData('name'));
        setCnpj(InitialData('cnpj'));
        setSocialReason(InitialData('social_reason'));
        setStateRegistration(InitialData('state_registration'));

        setRoad(InitialData('road'));
        setNumber(InitialData('number'));
        setNeighborhood(InitialData('neighborhood'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));    

        setListContact(InitialData('list_contact'));
        setListImg(InitialData('list_img'));

        setFacebook(InitialData('facebook'));
        setInstagram(InitialData('instagram'));        
        setYoutube(InitialData('youtube'));
        setTiktok(InitialData('tiktok'));
        setPinterest(InitialData('pinterest'));
        setSite(InitialData('site'));
        
        setUserName(DataLogin('name'));
        setUserEmail(DataLogin('email'));
        setUserPassword('');

        // cover
        setCoverFile('');
        setCoverPositionX(0);
        setCoverPositionY(0);
        setCoverWidth(0);
        setCoverHeight(0);
        setCoverShowWidth(0);
        setCoverWidthReal(0);

        // photo
        setPhotoFile('');
        setPhotoPositionX(0);
        setPhotoPositionY(0);
        setPhotoWidth(0);
        setPhotoHeight(0);
        setPhotoShowWidth(0);
        setPhotoWidthReal(0);
    }, [dataPage]);
    
    return(
        <form className="Page_Shopkeepers_Profile width_page" onSubmit={ SaveData }>
            <div className="div_file">
                {
                    idPage === 0 ? 
                        cover ?
                        <div className="show_cover" style={ { backgroundImage: 'url("' + URL.createObjectURL(cover) + '")' } }>
                            <label>
                                <div className="alt_cover">
                                    <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                    Editar capa
                                </div>
                            </label>
                        </div>
                        :
                        <div className="show_cover not_cover">
                            Capa não encontrada
                            <label>
                                <div className="alt_cover">
                                    <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                    Editar capa
                                </div>
                            </label>
                        </div>
                    :
                    <div className={ cover ? "show_cover" : "show_cover not_cover" } style={ { backgroundImage: 'url("' + cover + '")' } }>
                        {
                            cover ? null : "Capa não encontrada"
                        }
                        <label>
                            <div className="alt_cover">
                                <input type="file" onChange={ (e)=>{ CropImg('cover', e.target.files[0], 0) } } accept="image/*" />
                                Editar capa
                            </div>
                        </label>
                    </div>
                }

                <div className="show_img">
                    <div className="file_user">
                        {
                            file == "" ? <Svg_Logo_Abbreviated className="icons" /> :
                            <img al="img" src={ idPage === 0 ? URL.createObjectURL(file) : file } className="icons" />
                        }                        
                    </div>
                    <div className="alt_file" data-tooltip-id="alt_file_user" data-tooltip-content="Alterar sua foto" data-tooltip-place="top">
                        <label>
                            <input type="file" onChange={ (e)=>{ CropImg('file', e.target.files[0], 0) } } accept="image/*" />
                            <Svg_Edit className="icons" color={ colorIcon } />
                        </label>
                    </div>
                    <Tooltip id="alt_file_user" />
                </div>
            </div>

            <BtnSave return={ props.origin ? true : false } page="shopkeepers" ReturnPage={ props.ReturnPage } />

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados do login</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setUserName(e.target.value) } } value={ userName } maxLength={ 140 } required={ true } />
                        <span className="name_input">Nome*</span>
                    </div>
                    <div className="div_input">
                        <input type="email" maxLength={ 60 } onChange={ (e)=>{ setUserEmail(e.target.value.replaceAll(' ', '')) } } value={ userEmail } required={ true } />
                        <span className="name_input">E-mail*</span>
                    </div>
                    <div className="div_input">
                        <input type="password" onChange={ (e)=>{ setUserPassword(e.target.value.replaceAll(' ', '')) } } value={ userPassword } required={ GetUserData('nivel') != 0 ? idPage !=0 ? false : true : props.idPage !=0 ? false : true } />
                        <span className="name_input">
                            Senha
                            { GetUserData('nivel') != 0 ? idPage !=0 ? null : "*" : props.idPage !=0 ? null : "*" }
                        </span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados da empresa</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength={ 140 } />
                        <span className="name_input">Nome Fantasia*</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="cnpj" placeholder="000.000.000/0001-00" maxLength={ 20 } onChange={ (e)=>{ setCnpj(e.target.value) } } value={ cnpjMask(cnpj) } />
                        <span className="name_input">CNPJ</span>
                    </div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setSocialReason(e.target.value) } } value={ socialReason } />
                        <span className="name_input">Razão Social</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="cnpj" placeholder="000.000.000/0001-00" onChange={ (e)=>{ setStateRegistration(e.target.value) } } value={ cnpjMask(stateRegistration) } />
                        <span className="name_input">Inscrição Estadual</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Endereço</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input">
                        <input type="text" className="cep" placeholder="00000-000" maxLength={ 9 } onChange={ (e)=>{ setCep(e.target.value.replaceAll(' ', '')) } } value={ cepMask(cep) } />
                        <span className="name_input">CEP</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="uf" maxLength={ 2 } onChange={ (e)=>{ setUf(e.target.value.replaceAll(' ', '')) } } value={ uf } />
                        <span className="name_input">UF</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } />
                        <span className="name_input">Cidade</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setNeighborhood(e.target.value) } } value={ neighborhood } />
                        <span className="name_input">Bairro</span>
                    </div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setRoad(e.target.value) } } value={ road } />
                        <span className="name_input">Rua</span>
                    </div>
                    <div className="div_input">
                        <input type="text" className="number" onChange={ (e)=>{ setNumber(e.target.value.replaceAll(' ', '')) } } value={ number } />
                        <span className="name_input">Número</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Contato</div>
                    <div className="btn_save" onClick={ ()=>{ setListContact([...listContact, { "id": 0, "name": "", "email": "", "contact": "", "whatsapp": "" }]) } }>Adicionar novo contato</div>
                </div>
                
                {
                    listContact.length > 0 ?
                        listContact.map((elem, index)=>{
                            return(
                                <div className="list_input_data div_line_top" key={ index }>
                                    <div className="div_input space_div">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('name', index, e.target.value.replaceAll(' ', '')) } } value={ elem.name } maxLength={ 140 } />
                                        <span className="name_input">Nome</span>
                                    </div>
                                    <div className="div_input space_div">
                                        <input type="email" className="" onChange={ (e)=>{ HandleData('email', index, e.target.value.replaceAll(' ', '')) } } value={ elem.email } maxLength={ 60 } />
                                        <span className="name_input">E-mail</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('contact', index, e.target.value) } } value={ elem.contact } maxLength={ 40 } />
                                        <span className="name_input">Contato</span>
                                    </div>
                                    <div className="div_input">
                                        <input type="text" className="" onChange={ (e)=>{ HandleData('whatsapp', index, e.target.value) } } value={ elem.whatsapp } maxLength={ 30 } />
                                        <span className="name_input">Whatsapp</span>
                                    </div>
                                    <div className="div_input" onClick={ ()=>{ Delete(index, elem.id, elem.name) } }>
                                        <Svg_Delete className="icons" color="#f00000" />
                                    </div>
                                </div>
                            )
                        })
                    :
                    <div className="no_data">Nenhum contato encontrado...</div>
                }
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Redes Sociais / Site</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setFacebook(e.target.value.replaceAll(' ', '')) } } value={ facebook } />
                        <span className="name_input">Facebook</span>
                    </div>
                    {
                        facebook !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_facebook" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ facebook } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_facebook" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setInstagram(e.target.value.replaceAll(' ', '')) } } value={ instagram } />
                        <span className="name_input">Instagram</span>
                    </div>
                    {
                        instagram !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_instagram" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ instagram } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_instagram" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setYoutube(e.target.value.replaceAll(' ', '')) } } value={ youtube } />
                        <span className="name_input">Youtube</span>
                    </div>
                    {
                        youtube !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_youtube" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ youtube } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_youtube" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setTiktok(e.target.value.replaceAll(' ', '')) } } value={ tiktok } />
                        <span className="name_input">TikTok</span>
                    </div>
                    {
                        tiktok !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_tiktok" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ tiktok } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_tiktok" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setPinterest(e.target.value.replaceAll(' ', '')) } } value={ pinterest } />
                        <span className="name_input">Pinterest</span>
                    </div>
                    {
                        pinterest !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_pinterest" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ pinterest } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_pinterest" />
                        </>
                        : null
                    }
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setSite(e.target.value.replaceAll(' ', '')) } } value={ site } />
                        <span className="name_input">Site</span>
                    </div>
                    {
                        site !='' ?
                        <>
                            <div className="div_input" data-tooltip-id="link_site" data-tooltip-content="Clique aqui para abrir o link em uma nova aba do navegador" data-tooltip-place="top">
                                <a href={ site } target="_blank">
                                    <Svg_Link className="icons" color={ colorIcon } />
                                </a>
                            </div>
                            <Tooltip id="link_site" />
                        </>
                        : null
                    }
                </div>
            </div>

            {
                GetUserData('nivel') != 0 ?
                    idPage == 0 ? null :
                    <div className="show_page_data">
                        <div className="list_type_data">
                            <div className="type_title">Fotos</div>
                        </div>
                        <div className="list_input_data div_list_img">
                            {
                                listImg.map((elem, index)=>{                            
                                    return(
                                        <div className="div_input">
                                            <div className="show_img add_img" key={ index }>
                                                <div className="delete" data-tooltip-id={ "delete_" + elem.id + "_" + index } data-tooltip-content="Deletar foto" data-tooltip-place="top" onClick={ ()=>{ RemuveFile(elem.id) } }>
                                                    <Svg_Delete className="icons" color="#ffffff" />
                                                </div>
                                                <Tooltip id={ "delete_" + elem.id + "_" + index } />
                                                <div className="img_" style={ { backgroundImage: 'url("' + elem.file + '")' } } onClick={ ()=>{ OpenFile('showImg', elem.file) } } /> 
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="div_input">
                                <label>
                                    <input type="file" onChange={ (e)=>{ CropImg('list_Img', e.target.files[0]) } } accept="image/*" />
                                    <div className="show_img add_img" onClick={ ()=>{  } } data-tooltip-id="add_file_img" data-tooltip-content="Adicionar nova foto" data-tooltip-place="top">
                                        <Svg_Add className="icons" color="#B4B4B4" />
                                    </div>
                                    <Tooltip id="add_file_img" />
                                </label>
                            </div>
                        </div>
                    </div>
                :
                <div className="show_page_data">
                    <div className="list_type_data">
                        <div className="type_title">Fotos</div>
                    </div>
                    <div className="list_input_data div_list_img">
                        {
                            listImg.map((elem, index)=>{                            
                                return(
                                    <div className="div_input">
                                        <div className="show_img add_img" key={ index }>
                                            <div className="delete" data-tooltip-id={ "delete_" + elem.id + "_" + index } data-tooltip-content="Deletar foto" data-tooltip-place="top" onClick={ ()=>{ RemuveFile(elem.id) } }>
                                                <Svg_Delete className="icons" color="#ffffff" />
                                            </div>
                                            <Tooltip id={ "delete_" + elem.id + "_" + index } />
                                            <div className="img_" style={ { backgroundImage: 'url("' + elem.file + '")' } } onClick={ ()=>{ OpenFile('showImg', elem.file) } } /> 
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="div_input">
                            <label>
                                <input type="file" onChange={ (e)=>{ CropImg('list_Img', e.target.files[0]) } } accept="image/*" />
                                <div className="show_img add_img" onClick={ ()=>{  } } data-tooltip-id="add_file_img" data-tooltip-content="Adicionar nova foto" data-tooltip-place="top">
                                    <Svg_Add className="icons" color="#B4B4B4" />
                                </div>
                                <Tooltip id="add_file_img" />
                            </label>
                        </div>
                    </div>
                </div>
            }
        </form>
    )
}