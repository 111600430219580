import { useState, useEffect } from "react";

import './Details.css';

import { Svg_Delete, Svg_Img } from "components/Svg";

import { colorIcon } from "fixedData";

import { Reg_ProductTemplate } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import BtnSave from "components/BtnSave";

export default function Page_Administrator_ProductTemplate_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('product_template'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ highlightTitle, setHighlightTitle ] = useState(InitialData('highlight_title'));
    const [ typeGallery, setTypeGallery ] = useState(InitialData('type_gallery'));
    const [ listComponents, setListComponents ] = useState(InitialData('components'));
    const [ showListItens, setShowListItens ] = useState(ListData('itens'));

    function InitialData(type){ 
        if(idPage > 0){
            const newData = GetDataPage('product_template').find(item => item.id == idPage);
            if(newData){
                return newData[type];
            }
            if(type == "highlight_title"){
                return false;
            }
            return '';
        }
        if(type == "highlight_title"){
            return false;
        }
        return '';
    }

    function ListData(type){
        const newData = GetDataPage('list_data').filter(item => item.type == type);
        if(newData){
            return newData;
        }
        return [];
    }

    function HandleDataComponents(type, index, value){
        const newData = [...listComponents];
        newData[index][type] = value;
        setListComponents(newData);
    }
    function DeleteComponents(id, index){
        if(id == 0){
            const newData = [...listComponents];
            newData.splice(index, 1);
            setListComponents(newData);
        }else {
            SetModalData('Confirmation', { "type": "delete_all_list", "status": 0, "id": id, "origin": "product_template", "name": "Lista de itens" });
            SetModalState('Confirmation', true);
        }
    }

    function HandleDataComponentsAdd(index){
        const newData = [...listComponents];        
        newData[index]['itens'].push({ "id": 0, "name": "", "list": [{ "id": 0, "id_category": 0, "newCategory": "" }]});
        setListComponents(newData);
    }
    function HandleDataComponentsItens(type, index, index_1, value){
        const newData = [...listComponents]; 
        newData[index]['itens'][index_1][type] = value;
        setListComponents(newData);
    }
    function HandleDataComponentsItensAdd(index, index_1){
        const newData = [...listComponents];
        newData[index]['itens'][index_1]['list'].push({ "id": 0, "id_itens": 0, "newItens": "" });
        setListComponents(newData);
    }
    function DeleteComponentsItens(index, index_1, id, value){
        if(id == 0){
            const newData = [...listComponents];
            newData[index]['itens'].splice(index_1, 1);
            setListComponents(newData);
        }else {
            SetModalData('Confirmation', { "type": "delete_list_itens", "status": 0, "id": id, "origin": "product_template", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function HandleDataComponentsList(type, index, index_1, index_2, value){
        const newData = [...listComponents];
        newData[index]['itens'][index_1]['list'][index_2][type] = value;
        setListComponents(newData);
    }
    function DeleteComponentsList(index, index_1, index_2, id, value){
        if(id == 0){
            const newData = [...listComponents];
            newData[index]['itens'][index_1]['list'].splice(index_2, 1);
            setListComponents(newData);
        }else {
            SetModalData('Confirmation', { "type": "delete_itens", "status": 0, "id": id, "origin": "product_template", "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_ProductTemplate(userId, idPage, title, typeGallery, highlightTitle, listComponents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('product_template', setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        return ()=>{
            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('product_template', setDataPage);
            UnRegisterListPag('currentPageId', setIdPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('product_template'));

        setTitle(InitialData('title'));
        setHighlightTitle(InitialData('highlight_title'));
        setTypeGallery(InitialData('type_gallery'));
        setListComponents(InitialData('components'));
        setShowListItens(ListData('itens'));
    }, [dataPage]);

    return(
        <form className="Page_Administrator_ProductTemplate_Details width_page" onSubmit={ SaveData }>
            <BtnSave return={ true } page="product_template" ReturnPage={ props.ReturnPage } />

            <div className="show_page_data">
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength={ 140 } required />
                        <span className="name_input">Nome do modelo</span>
                    </div>
                    <div className="div_input">
                        <select className="inmetro" onChange={ (e)=>{ setHighlightTitle(e.target.value) } } value={ highlightTitle }>
                            <option value={ true }>Sim</option>
                            <option value={ false }>Não</option>
                        </select>
                        <span className="name_input">Destacar título</span>
                    </div>
                    <div className="div_input">
                        <select onChange={ (e)=>{ setTypeGallery(e.target.value) }} value={ typeGallery }>
                            <option value="">#</option>
                            <option value="UniqueImageContainer">Imagem única</option>
                            <option value="SelectImageContainer">Galeria de imagem</option>
                        </select>
                        <span className="name_input">Tipo de galeria</span>
                    </div>
                </div>
            </div>

            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Lista de especificações do produto</div>
                    <div className="btn_save" onClick={ ()=>{ setListComponents([...listComponents, { "id": 0, "name": "", "type": "", "size": "", "separator_": "", "nameNav": "", "itens": [] }]) } }>Adicionar nova especificação</div>
                </div>
                {
                    listComponents.length > 0 ?
                        listComponents.map((elem, index)=>{
                            return(                                
                                <div className="div_bloco_img" key={ index }>                                        
                                    <div className="list_input_data">
                                        <div className="div_input space_div">
                                            <input type="text" className="" onChange={ (e)=>{ HandleDataComponents('name', index, e.target.value) } } value={ elem.name } maxLength={ 140 } required />
                                            <span className="name_input">Nome</span>
                                        </div>
                                        <div className="div_input">
                                            <select onChange={ (e)=>{ HandleDataComponents('type', index, e.target.value) } } value={ elem.type }>
                                                <option value="">Selecione uma opção</option>
                                                <option value="list">Lista</option>
                                                <option value="links">links</option>
                                                {
                                                    listComponents.find(item => item.type == "nav") ? 
                                                    <option value="nav" hidden>Navegação</option> : <option value="nav">Navegação</option>
                                                }
                                                <option value="specifications">Especificação</option>
                                                <option value="specificationImage">Especificação com imagem</option>
                                            </select>
                                            <span className="name_input">Tipo</span>
                                        </div>
                                        <div className="div_input">
                                            <select onChange={ (e)=>{ HandleDataComponents('size', index, e.target.value) } } value={ elem.size }>
                                                <option value="medium">Médio</option>
                                                <option value="large">Grande</option>
                                            </select>
                                            <span className="name_input">Tamanho</span>
                                        </div>
                                        {
                                            elem.type === "nav" ? null :
                                            <div className="div_input space_div">
                                                <select onChange={ (e)=>{ HandleDataComponents('nameNav', index, e.target.value) } } value={ elem.nameNav }>
                                                    <option value="">Selecione uma opção</option>
                                                    {
                                                        elem.type !='nav' ?
                                                            listComponents.map((elem_1, index_1)=>{
                                                                if(elem_1.name !=''){
                                                                    if(elem_1.name != elem.name){
                                                                        return(
                                                                            <option value={ elem_1.name } key={ index_1 }>{ elem_1.name }</option>
                                                                        )
                                                                    }
                                                                }
                                                            })
                                                        : 
                                                        null
                                                    }
                                                </select>
                                                <span className="name_input">Nome da navegação</span>
                                            </div>
                                        }
                                        <div className="div_input">
                                            <input type="text" className="separator" onChange={ (e)=>{ HandleDataComponents('separator_', index, e.target.value) } } value={ elem.separator_ } maxLength={ 4 } />
                                            <span className="name_input">Separador</span>
                                        </div>
                                        <div className="div_input" onClick={ ()=>{ DeleteComponents(elem.id, index) } }>
                                            <Svg_Delete className="icons" color="#f00000" />
                                        </div>
                                    </div>
                                    {
                                        elem.type === "nav" ? 

                                        <div className="list_input_data div_data_input flex_direction_column">
                                            <div className="list_type_data space_top space_div" style={ { marginBottom: 0 } }>
                                                <div className="type_title">Lista de itens</div>
                                                <div className="div_btn">
                                                    <div className="btn_save" onClick={ ()=>{ HandleDataComponentsAdd(index) } }>
                                                        Adicionar nova lista
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                elem.itens.map((elem_1, index_1)=>{
                                                    return(
                                                        <div className="list_input_data div_line_top" key={ index_1 }>
                                                            <div className="list_input_data space_top_not">
                                                                <div className="div_input space_div">
                                                                    <input type="text" className="" onChange={ (e)=>{ HandleDataComponentsItens('name', index, index_1, e.target.value) } } value={ elem_1.name } maxLength={ 140 } />
                                                                    <span className="name_input">Nome da lista</span>
                                                                </div>
                                                                <div className="div_input btn_save" onClick={ ()=>{ HandleDataComponentsItensAdd(index, index_1) } }>
                                                                    Adicionar item
                                                                </div>
                                                                <div className="div_input" onClick={ ()=>{ DeleteComponentsItens(index, index_1, elem_1.id, "item") } }>
                                                                    <Svg_Delete className="icons" color="#f00000" />
                                                                </div>
                                                            </div>
                                                            {
                                                                elem_1.list.length > 0 ?
                                                                    <div className="list_input_data div_data_input show_data_line flex_direction_column">
                                                                        {
                                                                            elem_1.list.map((elem_2, index_2)=>{
                                                                                return(
                                                                                    <div className="data_line" key={ index_2 }>
                                                                                        <div className="div_input">
                                                                                            <select onChange={ (e)=>{ HandleDataComponentsList('id_itens', index, index_1, index_2, e.target.value) } } value={ elem_2.id_itens } required>
                                                                                                <option value="">Selecione uma opção</option>
                                                                                                <option value="addNewData">Adicionar novo nome</option>
                                                                                                {
                                                                                                    showListItens.map((elem_3, index_3)=>{
                                                                                                        return(
                                                                                                            <option value={ elem_3.id } key={ index_3 }>{ elem_3.name }</option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                            <span className="name_input">Nome</span>
                                                                                        </div>
                                                                                        {
                                                                                            elem_2.id_itens === "addNewData" ?
                                                                                            <div className="div_input space_div">
                                                                                                <input type="text" className="" onChange={ (e)=>{ HandleDataComponentsList('newItens', index, index_1, index_2, e.target.value) } } value={ elem_2.newItens } maxLength={ 40 } />
                                                                                                <span className="name_input">Novo nome</span>
                                                                                            </div>
                                                                                            : null
                                                                                        }
                                                                                        <div className="div_input" onClick={ ()=>{ DeleteComponentsList(index, index_1, index_2, elem_1.id, "item") } }>
                                                                                            <Svg_Delete className="icons" color="#f00000" />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                : null
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        : null
                                    }
                                </div>
                            )
                        })
                    :
                    <div className="no_data">Nenhuma especificação encontrada...</div>
                }
            </div>
        </form>
    )
}