import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Reg_AltStatus } from "services/RegisterData";

export default function PopUP_Confirmation(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ] = useState(GetUserData('id'));

    function TypeAlt(){
        props.setLoading(true);
        Reg_AltStatus(userId, modalData.type, modalData.id, modalData.origin, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
        SetModalState('Confirmation', false);
        SetModalState('DetailsUser', false);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
        SetModalState('Confirmation', false);
        SetModalState('DetailsUser', false);
    }

    function ClosePopUp(){
        props.setLoading(false);
        SetModalState('Confirmation', false);
    }

    function TypeRemoval(){
        switch(modalData.type){
            case "disabled_access_dash":
                return (
                    <div>
                        Desativar usuário
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "reactivate_access_dash":
                return (
                    <div>
                        Reativar usuário
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "delete_user":
                return (
                    <div>
                        Negar acesso ao usuário
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "approve_user":
                return (
                    <div>
                        Aprovar acesso ao usuário
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "delete_profile":
                return (
                    <div>
                        Excluir { modalData.typeData }
                        <div>{ modalData.name }</div>
                    </div>
                );
            case "alt_status_profile_0":
                return (
                    <div>
                        Desativar { modalData.typeData }
                        <div>{ modalData.name }</div>
                    </div>
                );
            default:
                return (
                    <div>
                        Deletar:
                        <div>{ modalData.name }</div>
                    </div>
                );
        }
    }

    useEffect(()=>{
        RegisterUserData('id', setUserId);

        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all confirmation">
                    <div className="div_data">
                        <div className="title">
                            { TypeRemoval() }
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content div_confirmation">
                            <div onClick={ ()=>{ TypeAlt(); } }>
                                <div className="opt_select yes_update">Sim</div>
                            </div>
                            <div onClick={ ()=>{ ClosePopUp(); } }>
                                <div className="opt_select not_update">Não</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
