import { useState, useEffect } from "react";

import './Downloads.css';

import PageTopic from "components/PageTopic";
import ListDownloads from "components/ListDownloads";

import { GetUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

export default function Page_Downloads(props){

    const [ search, setSearch ] = useState('');
    const [ currentData, setCurrentData ] = useState(GetDataPage('downloads'));
    const [ currentCategory, setCurrentCategory ] = useState(GetDataPage('downloads_category'));
    const [ showData, setShowData ] = useState(InitialData());
    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(showData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);
    
    function InitialData(){
        const newData_ = GetDataPage('downloads').filter(item => item.type == props.typePage);
        if(newData_){
            return newData_;
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('downloads').forEach(item =>{
                if(item.type == props.typePage){
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.name_category.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(InitialData());
        }
        setSearch(value);
    }

    function PageClick(idPage, title, file, category){
        SetModalData('EditOrNewDownloads', { "id": idPage, "origin": "downloads", "name": props.name, "category": category, "type": props.typePage, "title": title, "file": file });
        SetModalState('EditOrNewDownloads', true);
    }

    function DeletePhoto(id, value){
        SetModalData('Confirmation', { "id": id, "origin": "downloads", "type": "delete_photo", "status": 0, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('downloads', setCurrentData);
        RegisterDataPage('downloads_category', setCurrentCategory);
        return ()=>{
            UnRegisterDataPage('downloads', setCurrentData);
            UnRegisterDataPage('downloads_category', setCurrentCategory);
        };
    }, []);

    useEffect(()=>{
        setCurrentData(GetDataPage('downloads'));
        setCurrentCategory(GetDataPage('downloads_category'));
        setShowData(InitialData());
    }, [currentData, props.typePage]);

    return(
        <div className="width_page">
            <PageTopic type={ props.type } PageClick={ PageClick } SearchInput={ SearchInput } titlePage={ props.name } historic={ [] } />

            <ListDownloads type={ props.type } PageClick={ PageClick } DeletePhoto={ DeletePhoto } qtdData={ showData.length } setShowPage={ setShowPage } CurrentItens={ currentItens } startItens={ startItens } page={ page } showPage={ showPage } />
        </div>
    )
}