import React, { useState, useEffect } from "react";

import './EditOrNewDownloads.css';

import { GetDataPage } from "interface/Data";
import { GetUserData } from "interface/Users";
import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { Svg_Close } from "components/Svg";

import { Reg_ImagesOrPdf } from "services/RegisterData";

export default function PopUP_EditOrNewDownloads(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ userId, setUserId ] = useState(GetUserData('id'));

    const [ title, setTitle ] = useState('');
    const [ file, setFile ] = useState('');
    const [ category, setCategory ] = useState('');
    const [ newCategory, setNewCategory ] = useState('');

    function SaveData(event){
        if(category !='' && file !='' && title !=''){
            event.preventDefault();
            props.setLoading(true);
            Reg_ImagesOrPdf(userId, modalData.origin, modalData.type, modalData.id, category, newCategory, title, file, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        }else {
            SetModalData('ReturnResponse', { "page": "erro", "text": "É necessário preencher todos os dados para salvar!" });
            SetModalState('ReturnResponse', true);
        }
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);        
        ClosePopUp();
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function ClosePopUp(){
        setTitle('');
        setFile('');
        setCategory('');
        setNewCategory('');
        SetModalState('EditOrNewDownloads', false);
    }

    useEffect(()=>{
        RegisterModalData('EditOrNewDownloads', setModaldata);
        RegisterModalObserver('EditOrNewDownloads', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            if(modalData.id !=0){
                setTitle(modalData.title);
                setFile(modalData.file);
                setCategory(modalData.category);
                setNewCategory('');
            }
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp PopUpCenter" onSubmit={ SaveData }>
                <div className="all edit_or_new_downloads">
                    <div className="div_data type_div">
                        <div className="title">
                            {
                                modalData.id == 0 ? "Adicionar " + modalData.name : "Editar dados"
                            }
                        </div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="list_input_data">
                                <div className="div_input">
                                    <select onChange={ (e)=>{ setCategory(e.target.value) } } value={ category } required>
                                        <option value="" disabled>Selecione uma opção</option>
                                        <option value="newData">Adicionar nova categoria</option>
                                        {
                                            GetDataPage('downloads_category').map((elem, index)=>{
                                                return(
                                                    <option value={ elem.id } key={ index }>{ elem.name }</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className="name_input">Categoria*</span>
                                </div>
                                {
                                    category === 'newData' ?
                                        <div className="div_input space_div">
                                            <input type="text" className="space_width" onChange={ (e)=>{ setNewCategory(e.target.value) } } value={ newCategory } maxLength="140" required />
                                            <span className="name_input">Nome da nova categoria*</span>
                                        </div>
                                    : null
                                }
                            </div>
                            
                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="space_width" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                                    <span className="name_input">Título*</span>
                                </div>
                            </div>

                            <div className="list_input_data">
                                <div className="div_input space_div">
                                    <input type="text" className="space_width" onChange={ (e)=>{ setFile(e.target.value.replaceAll(' ', '')) } } value={ file } maxLength="140" required />
                                    <span className="name_input">Link google drive*</span>
                                </div>
                            </div>

                            <button type="submit" className="register_data button_edit_img">
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
