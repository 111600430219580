import { useState, useEffect } from "react";

import './ApprovalRegistrations.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageTopic from "components/PageTopic";
import { Svg_Checked, Svg_Checked_1, Svg_ChekedReturn, Svg_Close, Svg_Delete, Svg_Disabled, Svg_Search, Svg_User, Svg_User_1 } from "components/Svg";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { colorIcon } from "fixedData";

export default function Page_Administrator_ApprovalRegistrations(props){

    const [ search, setSearch ] = useState('');
    const [ currentData, setCurrentData ] = useState(GetDataPage('access_dash'));
    const [ showData, setShowData ] = useState(InitialData());
    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(showData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = showData.slice(startItens, endItens);

    function InitialData(){
        const newData = GetDataPage('access_dash').filter(item => item.status == 3);
        if(newData){
            return newData.filter(item => item.nivel == props.nivel);
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('access_dash').forEach(item =>{
                if(item.status == 3){
                    if(item.nivel == props.nivel){
                        if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                        if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowData(duplicate);
        }else {
            setShowData(InitialData());
        }
        setSearch(value);
    }

    function OpenDetailsUser(id, name, email, city, file){
        SetModalData('DetailsUser', { "id": id, "type": 0, "name": name, "email": email, "city": city, "file": file, "AltProfile": AltProfile });
        SetModalState('DetailsUser', true);
    }

    function AltProfile(type, id, value){
        SetModalData('Confirmation', { "id": id, "origin": 'approval_registrations', "type": type, "status": 0, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('access_dash', setCurrentData);
        return ()=>{
            UnRegisterDataPage('access_dash', setCurrentData);
        };
    }, []);

    useEffect(()=>{
        setCurrentData(GetDataPage('access_dash'));
        
        setShowData(InitialData());
    }, [currentData, props.nivel]);

    return(
        <div className="Page_Administrator_ApprovalRegistrations width_page">
            <PageTopic type="none" PageClick={ ()=>{ } } SearchInput={ SearchInput } titlePage={ props.title } historic={ [] } />

            <div className="list_project">
                {
                    currentItens.length > 0 ?
                    currentItens.map((elem, index)=>{
                        return(
                            <div className="show_data_approval_registrations" key={ index }>
                                <div className="div_data_user">
                                    <div className="div_img_user">
                                        {
                                            elem.file === "" ?
                                            <Svg_User_1 className="icons" color="#626262" />
                                            :
                                            <div className="icons" style={ { backgroundImage: 'url("' + elem.file + '")' } } />
                                        }
                                    </div>
                                    <div className="div_inf">
                                        <div className="show_name">{ elem.name }</div>
                                        <div className="show_email">{ elem.name_city }</div>
                                    </div>
                                </div>
                                <div className="div_opt_icons">
                                    <div className="div_icons" onClick={ ()=>{ OpenDetailsUser(elem.id, elem.name, elem.email, elem.name_city, elem.file) } } data-tooltip-id="inf_all" data-tooltip-content="Visualizar informações" data-tooltip-place="top">
                                        <Svg_Search className="icons" color={ colorIcon } />
                                    </div>  
                                    <div className="div_icons" style={ { background: colorIcon } } onClick={ ()=>{ AltProfile('approve_user', elem.id, elem.name) } } data-tooltip-id="inf_all" data-tooltip-content="Liberar acesso" data-tooltip-place="top">
                                        <Svg_Checked_1 className="icons icons_" color="#ffffff" />
                                    </div>                      
                                    <div className="div_icons icons_1" onClick={ ()=>{ AltProfile('delete_user', elem.id, elem.name) } } data-tooltip-id="inf_all" data-tooltip-content="Negar acesso" data-tooltip-place="top">
                                        <Svg_Close className="icons" color="#f00000" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <div className="show_data_approval_registrations">
                        <div className="div_data_user" style={ { width: "100%", textAlign: "center" } }>Nenhum cadastro encontrado...</div>
                    </div>
                }
                <Tooltip id="inf_all" />

                {
                    showData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}