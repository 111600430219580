import { useState, useEffect } from "react";

import './Details.css';

import TextareaAutosize from 'react-textarea-autosize';

import BtnSave from "components/BtnSave";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetListPag, RegisterListPag, UnRegisterListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData, UnRegisterUserPage } from "interface/Users";

import { Svg_Delete, Svg_Img, Svg_Vimeo, Svg_Youtube } from "components/Svg";

import { colorIcon } from "fixedData";

import { Reg_Blog } from "services/RegisterData";

export default function Page_Administrator_Blog_Details(props){

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('blog'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ subtitle, setSubtitle ] = useState(InitialData('subtitle'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ banner, setBanner ] = useState(InitialData('banner'));
    const [ bannerStatus, setBannerStatus ] = useState(false);

    function InitialData(type){ 
        if(idPage > 0){
            const newData = GetDataPage('blog').find(item => item.id == idPage);
            if(newData){
                return newData[type];
            } 
            return '';
        }            
        return '';
    }

    function AddFile(value){
        if(value){
            setBanner(value);
            setBannerStatus(true);
        }else {
            setBanner('');
            setBannerStatus(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_Blog(userId, idPage, title, subtitle, text, banner, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        props.setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro", "text": "" });
        SetModalState('ReturnResponse', true);
    }

    useEffect(()=>{
        RegisterListPag('currentPageId', setIdPage);

        RegisterUserData('id', setUserId);
        RegisterDataPage('blog', setDataPage);

        return ()=>{
            UnRegisterListPag('currentPageId', setIdPage);

            UnRegisterUserPage('id', setUserId);
            UnRegisterDataPage('blog', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        setDataPage(GetDataPage('blog'));

        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setText(InitialData('text'));
        setBanner(InitialData('banner'));
        setBannerStatus(false);
    }, [dataPage]);

    return(
        <form className="Page_Administrator_Blog_Details width_page" onSubmit={ SaveData }>
            <BtnSave return={ true } page="blog" ReturnPage={ props.ReturnPage } />
 
            <div className="show_page_data">
                <div className="list_type_data">
                    <div className="type_title">Dados do bloco</div>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength={ 140 } required />
                        <span className="name_input">Título*</span>
                    </div>
                    <div className="div_input space_div">
                        <input type="text" className="" onChange={ (e)=>{ setSubtitle(e.target.value) } } value={ subtitle } maxLength={ 140 } required />
                        <span className="name_input">Subtitle*</span>
                    </div>
                    <span className="div_input div_add_img">
                        <div className={ bannerStatus ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { bannerStatus ? "Foto adicionada" : "Adicionar foto" }
                                <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*" />
                            </label>
                        </div>
                        {
                            banner !='' ?
                            <>
                                <div className="delete_img_add" onClick={ ()=>{ AddFile(''); } }>
                                    <Svg_Delete className="icons" color="#ffffff" />
                                </div>
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', bannerStatus ? URL.createObjectURL(banner) : banner) } }>
                                    <Svg_Img color="#324d6b" className="icons"/>
                                </div>
                            </>
                            : null
                        }
                    </span>
                </div>
                <div className="list_input_data">
                    <div className="div_input space_div">
                        <span className="name_input">Descrição</span>
                        <TextareaAutosize className="textarea" onChange={ (e)=>{ setText(e.target.value) } } value={ text.replace(/<br\s*\/?>/gi, '\n') } />
                    </div>
                </div>
            </div> 
        </form>
    )
}