import { useState, useEffect } from "react";

import './ListDownloads.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { Svg_Delete, Svg_Edit, Svg_GoogleDrive } from "components/Svg";

import { colorIcon } from "fixedData";

export default function ListDownloads(props){

    return(
        <div className="ListImg">
            <table cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <th width="20" align="center">#</th>
                        <th>Nome</th>
                        <th width="300">Categoria</th>
                        <th width="40">Imagem</th>
                        {
                            props.type == "none" ? null : <th width="60" align="right">#</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.CurrentItens.length > 0 ?
                        props.CurrentItens.map((elem, index)=>{
                            return(
                                <tr key={ index }>
                                    <td align="center">{ props.startItens + index + 1 }</td>
                                    <td>{ elem.title }</td>
                                    <td>{ elem.name_category }</td>
                                    <td align="center">
                                        <a href={ elem.file } target="_blank" data-tooltip-id={ "id_googledrive_" + elem.id } data-tooltip-content="Clique aqui para abrir" data-tooltip-place="top">
                                            <Svg_GoogleDrive className="icons" color={ colorIcon } />
                                        </a>
                                        <Tooltip id={ "id_googledrive_" + elem.id } />
                                    </td>
                                    {
                                        props.type == "none" ? null :
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ props.PageClick(elem.id, elem.title, elem.file, elem.category) } } data-tooltip-id={ "id_edit_" + elem.id } data-tooltip-content="Editar nome da foto" data-tooltip-place="top">
                                                    <Svg_Edit className="icons" color={ colorIcon } />
                                                </div>
                                                <Tooltip id={ "id_edit_" + elem.id } />

                                                <div onClick={ ()=>{ props.DeletePhoto(elem.id, elem.title) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Deletar foto" data-tooltip-place="top">
                                                    <Svg_Delete className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_delete_" + elem.id } />
                                            </div>
                                        </td>
                                    }
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td className="no_data" align="center" colSpan={ props.type == "none" ? 4 : 5 }>Nenhum cadastro encontrado...</td>
                        </tr>
                    }
                </tbody>
            </table>
            {
                props.qtdData >= 50 ?
                <div className="list_pages">
                    {
                        Array.from(Array(props.page), (item, index)=>{
                            return(
                                <div className={ props.showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ props.setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}