import { useState } from 'react';

import './BtnDataLogin.css';

import { Svg_User } from 'components/Svg';

export default function BtnDataLogin(props){

    const [ nameUser, setNameUser ] = useState(ShowName());

    function ShowName(){
        let infName = props.userName.split(' ');
        if(infName[1] != undefined){
            return infName[0] + " " + infName[1];
        }else {
            return infName[0];
        }
    }
    
    return(
        <div className="opt_menu show_data_user">
            <div className="div_data_img">
                {
                    props.userFile == "" ? 
                    <Svg_User className="img_user" color="#ffffff" /> :
                    <img alt="user" src={ props.userFile } className="img_user" />
                }
            </div>
            <div className="div_data_user">
                <div className="hello">Olá,</div>
                <div className="name_user">
                    { nameUser }
                </div>
            </div>
        </div>
    )
}