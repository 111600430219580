import { useState, useEffect } from "react";

import './Blog.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageTopic from "components/PageTopic";
import { Svg_Delete, Svg_Edit } from "components/Svg";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { colorIcon } from "fixedData";

export default function Page_Administrator_Blog(props){

    const [ search, setSearch ] = useState('');
    const [ currentData, setCurrentData ] = useState(GetDataPage('blog'));
    const [ listData, setListData ] = useState(GetDataPage('blog'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listData.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listData.slice(startItens, endItens);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('blog').forEach(item =>{
                if(item.status == 1){
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.subtitle.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListData(duplicate);
        }else {
            setListData(GetDataPage('blog'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'blog_details');
        SetListPag('currentPageId', idPage);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "id": id, "origin": "blog", "type": "delete_data", "status": 0, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage('blog', setCurrentData);
        return ()=>{
            UnRegisterDataPage('blog', setCurrentData);
        };
    }, []);

    useEffect(()=>{
        setCurrentData(GetDataPage('blog'));        
        setListData(GetDataPage('blog'));
    }, [currentData]);

    return(
        <div className="width_page">
            <PageTopic type="block" PageClick={ PageClick } SearchInput={ SearchInput } titlePage="Blog" historic={ [] } />

            <div className="list_project">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="200">Subtitulo</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.title }</td>
                                        <td>{ elem.subtitle }</td>
                                        <td align="right">
                                            <div className="list_opt">                                                
                                                <div onClick={ ()=>{ PageClick(elem.id) } } data-tooltip-id={ "id_edit_" + elem.id } data-tooltip-content="Editar bloco" data-tooltip-place="top">
                                                    <Svg_Edit className="icons" color={ colorIcon } />
                                                </div>
                                                <Tooltip id={ "id_edit_" + elem.id } />

                                                <div onClick={ ()=>{ DeleteData(elem.id, elem.title) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Deletar bloco" data-tooltip-place="top">
                                                    <Svg_Delete className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_delete_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 4 }>Nenhum cadastro encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    listData.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}