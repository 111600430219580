
let DataPage = {
    "access_dash": [],
    "architects": [],
    "project": [],
    "shopkeepers": [],
    "assistants": [],
    "downloads": [],
    "downloads_category": [],
    "product": [],
    "approval_of_registrations": [],
    "product_template": [],
    "blog": [],
    "technical_assistance": []
};

let NotifyDataPage = {
    "access_dash": [],
    "architects": [],
    "project": [],
    "shopkeepers": [],
    "assistants": [],
    "downloads": [],
    "downloads_category": [],
    "product": [],
    "list_data": [],
    "approval_of_registrations": [],
    "product_template": [],
    "blog": [],
    "technical_assistance": []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });    
    NotifyDataPage["architects"].forEach(element => { element(value['architects']); });
    NotifyDataPage["project"].forEach(element => { element(value['project']); });
    NotifyDataPage["shopkeepers"].forEach(element => { element(value['shopkeepers']); });
    NotifyDataPage["assistants"].forEach(element => { element(value['assistants']); });
    NotifyDataPage["downloads"].forEach(element => { element(value['downloads']); });
    NotifyDataPage["downloads_category"].forEach(element => { element(value['downloads_category']); });
    NotifyDataPage["product"].forEach(element => { element(value['product']); });
    NotifyDataPage["list_data"].forEach(element => { element(value['list_data']); });
    NotifyDataPage["approval_of_registrations"].forEach(element => { element(value['approval_of_registrations']); });
    NotifyDataPage["product_template"].forEach(element => { element(value['product_template']); });
    NotifyDataPage["blog"].forEach(element => { element(value['blog']); });
    NotifyDataPage["technical_assistance"].forEach(element => { element(value['technical_assistance']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}