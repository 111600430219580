import React, { useState, useEffect } from "react";

import './DetailsUser.css';

import { GetDataPage } from "interface/Data";
import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Svg_Checked_1, Svg_Close, Svg_User_1 } from "components/Svg";

import { colorIcon } from "fixedData";

export default function PopUP_DetailsUser(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ file, setFile ] = useState('');
    const [ name, setName ] = useState('');
    const [ nameCity, setNameCity ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ listInf, setListInf ] = useState({});

    function InitialData(){
        if(modalData.type == 0){
            const newData = GetDataPage('shopkeepers').find(item => item.id_given == modalData.id);
            if(newData){
            console.log(newData);
                return newData;
            }
            return {};

        }else if(modalData.type == 1){
            const newData = GetDataPage('architects').find(item => item.id_given == modalData.id);
            if(newData){
                return newData;
            }
            return {};
        }
    }

    function ClosePopUp(){
        setListInf({});
        SetModalState('DetailsUser', false);
    }

    useEffect(()=>{
        RegisterModalData('DetailsUser', setModaldata);
        RegisterModalObserver('DetailsUser', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setListInf(InitialData());
            setFile(modalData.file);
            setName(modalData.name);
            setNameCity(modalData.city);
            setEmail(modalData.email);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp">
                <div className="all DetailsUser">
                    <div className="div_data type_div" style={ { paddingBottom: 0 } }>
                        <div className="title" />
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color="#F00000" className="icons" />
                        </div>
                    </div>
                    <div className="div_data" style={ { paddingTop: 0 } }>
                        <div className="content">
                            <div className="div_img_user">
                                {
                                    file === "" ?
                                    <Svg_User_1 className="icons" color="#626262" />
                                    :
                                    <div className="icons" style={ { backgroundImage: 'url("' + file + '")' } } />
                                }
                            </div>
                            <div className="div_inf_user">
                                <div className="div_input">
                                    <input type="text" className="name" placeholder="teste@teste.com" value={ name } readOnly />
                                    <span className="name_input">Nome</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="email" placeholder="teste@teste.com" value={ email } readOnly />
                                    <span className="name_input">E-mail</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="cnpj" placeholder="000.000.000-00" value={ listInf.cnpj } readOnly />
                                    <span className="name_input">CNPJ</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="cep" placeholder="xxxxx-xx" value={ listInf.cep } readOnly />
                                    <span className="name_input">CEP</span>
                                </div>
                                {/* 
                                <div className="div_input">
                                    <input type="text" className="state" placeholder="" value={ listInf.state_registration } readOnly />
                                    <span className="name_input">Estado</span>
                                </div> 
                                */}
                                <div className="div_input">
                                    <input type="text" className="state" placeholder="" value={ nameCity } readOnly />
                                    <span className="name_input">Cidade</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="state" placeholder="" value={ listInf.neighborhood } readOnly />
                                    <span className="name_input">Bairro</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="road" placeholder="" value={ listInf.road } readOnly />
                                    <span className="name_input">Rua</span>
                                </div>
                                <div className="div_input">
                                    <input type="text" className="number" placeholder="" value={ listInf.number } readOnly />
                                    <span className="name_input">Número</span>
                                </div>
                            </div>
                            <div className="btn_alt_status">
                                <div className="div_icons" style={ { background: colorIcon } } onClick={ ()=>{ modalData.AltProfile('approve_user', modalData.id, name) } }>
                                    <Svg_Checked_1 className="icons icons_" color="#ffffff" />
                                </div>
                                <div className="div_icons icons_1" onClick={ ()=>{ modalData.AltProfile('delete_user', modalData.id, name) } }>
                                    <Svg_Close className="icons" color="#f00000" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
