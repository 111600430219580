import './BtnSave.css';

import { colorIcon } from 'fixedData';

import { Svg_Arrow, Svg_Save } from 'components/Svg';

export default function BtnSave(props){
    
    return(
        <div className="div_btn_save">
            {
                props.return ?
                    <div className="topic_return_admin" onClick={ ()=>{ props.ReturnPage(props.page) } }>
                        <Svg_Arrow className="icons" color={ colorIcon } />
                        Voltar
                    </div>
                :
                null
            }
            <div className="" style={ { flexGrow: 1 } } />
            
            <button className="div_new_data">
                <div className="new_data_add">
                    <Svg_Save className="icons" color="#ffffff" />
                </div>
                <div className="name_btn_add">Salvar</div>
            </button>
        </div>
    )
}