// Connection type
export const connectionPage = '.';

// Cookies type
// export const cookiesRegister = './';
// export const cookiesRegister = 'dashboard.albacete.com.br/';
export const cookiesRegister = 'dashboard-loja1.fastevolution.com.br/';

// Cookies name
export const typeCookie = 'albacete_dash';
export const typeCookiePassw = 'albacete_passw';
export const typeCookieEmail = 'albacete_email';

export const typeCookiePage = 'albacete_page';
export const typeCookiePageId = 'albacete_page_id';

export const colorIcon = "#20489E";

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'ul', 'ol'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "Digite o texto aqui...",
    toolbarAdaptive: false
}