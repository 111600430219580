import { useState, useEffect } from "react";

import './Profile.css';

import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import PageTopic from "components/PageTopic";
import { Svg_Delete, Svg_Disabled, Svg_Edit } from "components/Svg";

import { SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { colorIcon } from "fixedData";

export default function Page_Administrator_Profile(props){

    const [ search, setSearch ] = useState('');
    const [ currentData, setCurrentData ] = useState(GetDataPage(props.origin));

    const [ listOn, setListOn ] = useState(InitialData('all'));
    const [ listOff, setListOff ] = useState(InitialData('deleted'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listOn.slice(startItens, endItens);

    function InitialData(type){
        if(type == "all"){
            const newData = []
            GetDataPage(props.origin).map((elem, index)=>{
                let infUser = GetDataPage('access_dash').find(item => item.id == elem.id_given);
                if(infUser){
                    if(infUser.status === 1){
                        if(newData.find(item => item.id_given == elem.id_given)){ }else {
                            newData.push(elem);
                        }
                    }
                }
            });
            return newData;
        }
        if(type == "deleted"){
            const newData = [];
            GetDataPage(props.origin).map((elem, index)=>{
                if(elem.status == 0){
                    newData.push({ "id": elem.id, "type": props.origin, "title": elem.userName, "removed_name": elem.removed_name, "removed_date": elem.removed_date })
                }
            });
            return newData;
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage(props.origin).forEach(item =>{
                if(item.status == 1){
                    if(item.userName.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.userEmail.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListOn(duplicate);
        }else {
            setListOn(InitialData('all'));
        }
        setSearch(value);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'administrator_' + props.origin + '_details');
        SetListPag('currentPageId', idPage);
    }

    function AltProfile(type, id, value){
        SetModalData('Confirmation', { "id": id, "typeData": props.name.slice(0, -1), "origin": props.origin, "type": type, "status": 0, "name": value });
        SetModalState('Confirmation', true);
    }

    useEffect(()=>{
        RegisterDataPage(props.origin, setCurrentData);
        return ()=>{
            UnRegisterDataPage(props.origin, setCurrentData);
        };
    }, []);

    useEffect(()=>{
        setCurrentData(GetDataPage(props.origin));
        
        setListOn(InitialData('all'));
        setListOff(InitialData('deleted'));
    }, [currentData, props.origin]);

    return(
        <div className="width_page">
            <PageTopic type="block" PageClick={ PageClick } SearchInput={ SearchInput } titlePage={ props.name } historic={ listOff } />

            <div className="list_project">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th width="60" align="right">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ elem.userName }</td>
                                        <td>{ elem.userEmail }</td>
                                        <td align="right">
                                            <div className="list_opt">
                                                <div onClick={ ()=>{ PageClick(elem.id, elem.title, elem.file) } } data-tooltip-id={ "id_edit_" + elem.id } data-tooltip-content="Editar perfil" data-tooltip-place="top">
                                                    <Svg_Edit className="icons" color={ colorIcon } />
                                                </div>
                                                <Tooltip id={ "id_edit_" + elem.id } />

                                                <div onClick={ ()=>{ AltProfile('alt_status_profile_0', elem.id, elem.userName) } } data-tooltip-id={ "id_access_" + elem.id } data-tooltip-content="Remover acesso do perfil" data-tooltip-place="top">
                                                    <Svg_Disabled className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_access_" + elem.id } />

                                                <div onClick={ ()=>{ AltProfile('delete_profile', elem.id, elem.userName) } } data-tooltip-id={ "id_delete_" + elem.id } data-tooltip-content="Deletar perfil" data-tooltip-place="top">
                                                    <Svg_Delete className="icons" color="#F00000" />
                                                </div>
                                                <Tooltip id={ "id_delete_" + elem.id } />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td className="no_data" align="center" colSpan={ 5 }>Nenhum cadastro encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    listOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}